<template>
  <div>
    <vs-input
      v-validate="'required|email|min:3'"
      name="email"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      :label-placeholder="$t('_login.E_mail')"
      v-model="email"
      :rules="validations.emailRules"
      class="w-full"/>
    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <vs-input
      v-validate="'required|min:6'"
      type="password"
      name="password"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      :label-placeholder="$t('_login.Password')"
      v-model="password"
      :rules="validations.passwordRules"
      class="w-full mt-6"
      @keydown.enter="loginJWT"/>
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <div class="flex flex-wrap justify-between my-5">
      <vs-checkbox v-model="checkbox_remember_me" class="mb-3">{{this.$t('_login.Remember_Me')}}</vs-checkbox>
      <router-link to="/pages/forgot-password">{{this.$t('_login.Forgot_Password')}}</router-link>
    </div>
    <div class="flex flex-wrap justify-between mb-3">
      <vs-button type="border" @click="registerUser">{{this.$t('_login.Register')}}</vs-button>
      <vs-button :disabled="!validateForm" @click="loginJWT">{{this.$t('_login.Login')}}</vs-button>
    </div>
  </div>
</template>

<script>
  import router from "@/router.js"

  export default {
    data() {
      return {
        valid: true,
        email: '',
        password: '',
        checkbox_remember_me: false,
        validations: {
          emailRules: this.$goc.validate.create().required().email(),
          passwordRules: this.$goc.validate
            .create()
            .required()
            .min(6),
        }
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.email != '' && this.password != '';
      },
    },
    mounted() {
      let userDetailsCookie = null;
      if(this.$cookies.isKey("lms_rememberme")) {
        userDetailsCookie = this.$cookies.get("lms_rememberme");
        this.email = userDetailsCookie.email;
        this.password = userDetailsCookie.password;
        this.checkbox_remember_me = true;
      }
    },
    methods: {
      checkLogin() {
        // If user is already logged in notify
        if (this.$store.state.auth.isUserLoggedIn()) {

          // Close animation if passed as payload
          // this.$vs.loading.close()

          this.$vs.notify({
            title: this.$t("_login.Login_Attempt"),
            text: this.$t("_login.Already_logged_in"),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          });

          return false
        }
        return true
      },
      loginJWT() {
        if (!this.checkLogin()) return;

        // Loading
        this.$vs.loading();

        let payload = {
          checkbox_remember_me: this.checkbox_remember_me,
          userDetails: {
            email: this.email,
            password: this.password
          }
        };

        this.$store.dispatch('auth/loginJWT', payload)
          .then((role) => {
            this.$vs.loading.close();
            this.$acl.change(role);
            if(payload.checkbox_remember_me) {
              this.$cookies.set("lms_rememberme", payload.userDetails, Infinity);  // never expire
            } else {
              this.$cookies.remove("lms_rememberme");
            }
            router.push(router.currentRoute.query.to || '/dashboard/analytics');
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$store.state.isLoggedIn = false;
            this.$goc.notify.error({message: error.data.Message, title: 'Login Error'})
          })
      },
      registerUser: function () {
        if (!this.checkLogin()) return;
        this.$router.push('/pages/register')
          .catch(() => {
          })
      }
    }
  }

</script>

